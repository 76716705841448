import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
// @ Components
import GridContainer from 'components/Grid/GridContainer.jsx'
import DocumentTitle from 'react-document-title'
import Section from '../../components/Section/Section'

// @ Context and Styles
import * as S from './styles'
import { CardServicesLogs } from './PaymentServicesLog/CardServicesLog'
import { CardServices } from './PaymentServicesLog/CardServices'
// import { getLogs } from './PaymentServicesLog/CardServices/CardLog/TabPanel/utils'
// import { GetAllTransactionLogs } from '../../redux/api/paymentGateway'
import Button from 'components/CustomButtons/Button.jsx'
import { getProgramTitle } from '../../utils/utils'
import { Box } from '@mui/material'

function NotificationCenter() {
    const { t } = useTranslation()

    const [data, setData] = useState(false)
    const [isNotification, setIsNotification] = useState(true)

    return (
        <Box px={'15px'}>
            <GridContainer>
                <DocumentTitle
                    title={getProgramTitle(t('Central de notificações'))}
                />
                <S.TabSection open={data}>
                    <Section title={t('Central de notificações')} />
                    {data && (
                        <Button
                            size="sm"
                            color="greenBorderButtonColor"
                            onClick={() => setData(false)}
                        >
                            {t('BUTTON_BACK')}
                        </Button>
                    )}
                </S.TabSection>

                {isNotification ? (
                    <S.Container>
                        {!data ? (
                            <CardServicesLogs
                                childToParent={(childdata) =>
                                    setData(childdata)
                                }
                            />
                        ) : (
                            <CardServices />
                        )}
                    </S.Container>
                ) : (
                    <S.TabEmpty>
                        <i className="icon-informacao" />
                        <p>Não há notificações a serem exibidas</p>
                    </S.TabEmpty>
                )}
            </GridContainer>
        </Box>
    )
}

export default NotificationCenter
