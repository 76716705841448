import React, { useEffect, useState } from 'react'
import { SearchService } from '../Services'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'
import { useForm } from 'react-hook-form'

export default function useSearch() {
    const { id } = useParams()
    const { t } = useTranslation()
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        errors,
        formState: { isValid: formIsValid },
    } = useForm({
        mode: 'all',
    })

    const watchfields = watch()

    const [theme, setTheme] = useState(GetThemeColorFromStorage())

    const [isLoadingData, setIsLoadingData] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [postSuccess, setPostSuccess] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [dataSurveyDetails, setDataSurveyDetails] = useState([])
    const [errorMessage, setErrorMessage] = useState('')

    async function GetSurveyDetails() {
        setIsLoadingData(true)
        const response = await SearchService.getSurveyDetails(id)
        if (response.errors.length > 0) {
            setErrorMessage(t(response?.errors[0]?.message))
        } else {
            setDataSurveyDetails(response?.data)
        }
        setIsLoadingData(false)
    }

    async function PostSurveyDetails(formData) {
        try {
            const response = await SearchService.postSurveyDetails(formData)
            const error = response?.errors[0]?.message.split(' - ')

            if (response.errors.length > 0) {
                setPostSuccess(false)
                setErrorMessage(t(error[1]) || 'Error ao realizar a pesquisa!')
            } else {
                setPostSuccess(true)
            }

            return response?.data
        } catch (e) {
            console.log(e)
            setPostSuccess(false)
            setErrorMessage('Error ao realizar a pesquisa!')
        } finally {
            setIsLoading(!isLoading)
        }
    }

    function handleLoader() {
        setIsLoading(!isLoading)
    }

    const onSubmit = async (data) => {
        setIsLoading(true)
        try {
            const quizResponses = Object.entries(data)
                .map(([key, value]) => ({
                    questionId: key,
                    responses: Array.isArray(value)
                        ? value
                        : value
                        ? [value]
                        : null,
                }))
                .filter((response) => response?.responses !== null) // Remove respostas nulas

            const formData = {
                surveyId: id,
                quizResponses,
            }

            return await PostSurveyDetails(formData)
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const checkRequiredFields = () => {
            return dataSurveyDetails?.quiz?.every((item) => {
                if (item.required) {
                    const value = watchfields[item.id]
                    return value && value?.length > 0
                }
                return true
            })
        }

        setIsValid(formIsValid && checkRequiredFields())
    }, [watchfields, formIsValid, dataSurveyDetails])

    return {
        isLoadingData,
        t,
        dataSurveyDetails,
        GetSurveyDetails,
        theme,
        setTheme,
        handleSubmit,
        control,
        watchfields,
        setValue,
        PostSurveyDetails,
        onSubmit,
        postSuccess,
        errorMessage,
        setErrorMessage,
        isValid,
        handleLoader,
        isLoading,
        errors,
    }
}
