import React, { useEffect, useState } from 'react'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Box, Grid } from '@material-ui/core'
import Stack from '@mui/material/Stack'

import { moneyFormatValue } from 'utils/utils'

import { ModalContainer, PaperContainer, Dotted } from './styles'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { CustomButtonsBackground } from '../../../../components/CustomButtons'

function AppModal({
    className,
    closeByClick,
    id,
    maxWidth,
    minWidth,
    open,
    shadow,
    t,
    type,
    typeModal,
    data,
}) {
    const [theme, setTheme] = useState(GetThemeColorFromStorage())
    const [isGiftCard, setIsGiftCard] = useState(true)

    const [showModal, setShowModal] = useState({
        loading: false,
        open: false,
    })

    const [paymentMethods, setPaymentMethods] = useState([])

    useEffect(() => {
        if (data) {
            console.log(data?.redeemed)
            // eslint-disable-next-line no-unused-expressions
            data?.redeemed?.map((redeemed) => {
                setPaymentMethods((prevPaymentMethods) => [
                    ...prevPaymentMethods,
                    {
                        redeemedKey: redeemed?.type?.key,
                        currencySymbol:
                            redeemed?.type?.key === '1'
                                ? Storage('programCurrencySymbol')
                                : Storage('currencySymbol'),
                        type: redeemed?.type?.value,
                        value: redeemed?.redeemedValue || redeemed?.value,
                    },
                ])
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const Storage = (_key) => localStorage.getItem(_key)

    useEffect(() => {
        setTheme(GetThemeColorFromStorage())
    }, [])

    return (
        <ModalContainer
            aria-describedby="alert-dialog-slide-description"
            aria-labelledby="alert-dialog-slide-title"
            className={className}
            disableEnforceFocus
            id={id}
            isGiftCard={isGiftCard}
            maxwidth={maxWidth}
            minwidth={minWidth}
            onClose={closeByClick}
            open={open}
            shadow={shadow}
            type={type}
        >
            <PaperContainer
                headerTitle={t('t Detalhes da forma de pagamento')}
                width="700px"
                headerIcon={false}
                closeByClick={() =>
                    setShowModal((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
                onClose={() =>
                    setShowModal((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
                open={showModal.open}
                buttonTitle="BUTTON_CLOSE"
            >
                <header>
                    <div className="section-part">
                        <div
                            className="section-header"
                            style={{
                                color: theme?.titles?.main?.textColor,
                            }}
                        >
                            {typeModal === 1
                                ? t('PROOF_PURCHASE_APP')
                                : typeModal === 2
                                ? t('PAYMENT_METHOD_DETAILS')
                                : t('PAYMENT_METHOD_DETAILS')}
                        </div>
                    </div>
                    <div className="close-part">
                        <CustomButtonsBackground
                            type="button"
                            onClick={closeByClick}
                            style={{
                                backgroundColor: '#F05B4F',
                                borderColor: '#F05B4F',
                                height: '40px',
                            }}
                        >
                            <i
                                className="icon-ico_cancelar"
                                style={{
                                    color: '#fff',
                                    fontSize: '16px',
                                }}
                            />
                            {t('BUTTON_CLOSE')}
                        </CustomButtonsBackground>
                    </div>
                </header>

                <Grid container className="card__content">
                    <Grid item xs={6}>
                        <div className="label">{t('INSTITUTION_RECEIVED')}</div>
                        <div className="text__value">
                            {data?.partnerName ?? ' - '}
                        </div>
                    </Grid>

                    <Grid item xs={6}>
                        <div className="label">Data da compra</div>
                        <div className="text__value">
                            {data?.date
                                ? `${moment(data.date).format(
                                      'DD/MM/YYYY',
                                  )} - ${moment(data.date).format('HH:mm')}`
                                : ' - '}
                        </div>
                    </Grid>

                    <Grid sm={12} lg={12} xs={12} sx={{ width: '100%' }}>
                        <>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ maxWidth: '59%' }}
                            >
                                {true && (
                                    <>
                                        <Stack>
                                            <div className="label">
                                                Forma de pagamento
                                            </div>
                                            {paymentMethods.map((item) => (
                                                <Box
                                                    className="text__value"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        width: '320px',
														whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {t(item?.type)}
                                                    <Dotted />
                                                </Box>
                                            ))}
                                        </Stack>
                                    </>
                                )}
                                {true && (
                                    <Stack>
                                        <div className="label">Valores</div>
                                        {paymentMethods.map((item) => (
                                            <Box
                                                className="text__value"
                                                sx={{ whiteSpace: 'nowrap' }}
                                            >
                                                {item?.currencySymbol}{' '}
                                                {item?.redeemedKey === '1'
                                                    ? item?.value
                                                    : moneyFormatValue(
                                                          `${item?.value}`,
                                                      )}
                                            </Box>
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        </>
                        {typeModal === 1 &&
                            data?.generated?.map((index) => (
                                <>
                                    <Grid item xs={6}>
                                        <div className="label">
                                            {t('PAYMENT_METHOD')}
                                        </div>
                                        <div className="text__value">
                                            {index.type.value ===
                                            'TRANSACTION' ? (
                                                <span>
                                                    <i
                                                        className="icon-carteira_digital"
                                                        style={{
                                                            fontSize: '20px',
                                                            marginRight: '10px',
                                                        }}
                                                    />
                                                    {t('DIGITAL_WALLET')}
                                                </span>
                                            ) : (
                                                index?.type?.value ===
                                                    'OPERATION' && (
                                                    <span>
                                                        <i
                                                            className="icon-solicitar_pontos"
                                                            style={{
                                                                fontSize:
                                                                    '20px',
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                        />
                                                        {t('POINTS')}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="label">
                                            {t('PAYMENT_VALUE')}
                                        </div>
                                        <div className="text__value">
                                            {index?.type?.value ===
                                            'TRANSACTION' ? (
                                                <span>
                                                    {localStorage.getItem(
                                                        'currencySymbol',
                                                    )}
                                                    {moneyFormatValue(
                                                        `${index?.value}`,
                                                    )}
                                                </span>
                                            ) : (
                                                index.type.value ===
                                                    'OPERATION' && (
                                                    <span>
                                                        {localStorage.getItem(
                                                            'programCurrencySymbol',
                                                        )}
                                                        {index.value}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </Grid>
                                </>
                            ))}
                    </Grid>
                </Grid>
            </PaperContainer>
        </ModalContainer>
    )
}

AppModal.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    closeByClick: PropTypes.func,
    error: PropTypes.string,
    id: PropTypes.string,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onDelete: PropTypes.func,
    open: PropTypes.bool,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    parameter: PropTypes.string,
    password: PropTypes.string,
    shadow: PropTypes.string,
    standardFocus: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
}

AppModal.defaultProps = {
    children: null,
    className: '',
    closeByClick: null,
    error: '',
    id: '',
    maxWidth: '',
    minWidth: '',
    onCancel: null,
    onConfirm: null,
    onDelete: null,
    open: false,
    padding: '',
    parameter: '',
    password: '',
    shadow: '',
    standardFocus: 'no',
    text: '',
    type: '',
}

export default withTranslation()(AppModal)
